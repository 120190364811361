import { inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationDialog } from '@shared/dialogs/confirmation/confirmation.dialog';
import { firstValueFrom } from 'rxjs';
import { ConfirmDialogArgs } from '@shared/dialogs/confirmation/ConfirmDialogArgs';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  private dialog = inject(MatDialog);
  private translateService = inject(TranslateService);

  showConfirmDialog(args?: Partial<ConfirmDialogArgs>) {
    const { dismissDialog, ...confirmDialogData } = { ...args };
    const modalData: ConfirmDialogArgs = {
      title: this.translateService.instant('approvalNotification.title'),
      subTitle: this.translateService.instant('approvalNotification.message'),
      confirm: this.translateService.instant('shared.confirm'),
      cancel: this.translateService.instant('shared.cancel'),
      actions: {
        confirm: {
          show: true,
        },
      },
      ...confirmDialogData,
    };
    const dialogRef = this.dialog.open(ConfirmationDialog, {
      width: '500px',
      data: modalData,
      disableClose: !!dismissDialog,
    });

    return firstValueFrom(dialogRef.afterClosed());
  }
}
